<template>
  <div :class="[soft ? 'soft' : '', 'loading']">
    <div class="inner-loading">
      <img :src="imageURL" />
      <div class="load-slide">
        <div class="line"></div>
        <div class="subline inc"></div>
        <div class="subline dec"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "loading-logo",
  props: {
    soft: Boolean,
  },
  computed: {
    ...mapGetters({ customLogo: "user/getUserLogoURL" }),
    imageURL() {
      return this.customLogo || require("../../../public/assets/loading-logo.svg");
    },
  },
};
</script>
<style scoped>
/* body {
  background: #ffffff;
  margin: 50px 300px;
} */
.loading {
  /*   position: absolute;
 */
  width: 100vw;
  background-color: rgb(32, 34, 47);
  height: 100vh;
  z-index: 99999999999;
  color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inner-loading {
  height: 600px;
  width: 600px;
  position: absolute;
  justify-content: center;
  display: flex;
}

img {
  position: absolute;
  width: 200px;
  top: 30%;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
}
.load-slide {
  position: relative;
  width: 300px;
  height: 7px;
  overflow-x: hidden;
  display: flex;
  margin-top: 380px;
}

.line {
  position: absolute;
  opacity: 0.4;
  background: white;
  width: 150%;
  height: 7px;
}

.subline {
  position: absolute;
  background: white;
  height: 7px;
}
.inc {
  animation: increase 2s infinite;
}
.dec {
  animation: decrease 2s 0.5s infinite;
}
.soft {
  background-color: rgba(0, 0, 0, 0.65) !important;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}
</style>
