<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" :style="{ minHeight: minHeight, minWidth: minWidth }" :class="[contentOnly ? 'only-one-big-row' : '']">
        <header v-if="!contentOnly">
          <slot name="header"></slot>
        </header>
        <main>
          <slot name="content"></slot>
        </main>
        <footer v-if="!contentOnly">
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalTemplate",
  props: {
    contentOnly: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: String,
      default: "680 px",
    },
    minHeight: {
      type: String,
      default: "400 px",
    },
  },
  methods: {
    closeMe() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: fit-content;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  height: fit-content;
  /* min-width: 680px; */
  /* min-height: 400px; */
  /* top: 22vh; 
  left: 0; */
  position: sticky;
  grid-template-rows: 50px 10fr 50px;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
}

.only-one-big-row {
  grid-template-rows: 10fr;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
header {
  border-bottom: solid 2px white;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
footer {
  border-top: solid 2px white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  z-index: 2;
}
h2 {
  font-weight: 400;
  font-size: 1.2em;
  padding-right: 40px;
}
</style>
