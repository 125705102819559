function validateFullName(fullName) {
  var re = /^[a-z]([-']?[a-z]+)*( [a-z]([-']?[a-z]+)*)+$/;
  return fullName && re.test(fullName.toLowerCase());
}

function validateName(name) {
  var re = /(?=.{4,24}$)^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/;
  return name && re.test(name.toLowerCase());
}

function validateStructName(structName) {
  var re = /^.{2,24}$/;
  return structName && re.test(structName);
}

function validateSpanName(spanName) {
  var re = /^.{1,24}$/;
  return spanName && re.test(spanName);
}

function validateStructAddress(structAddr) {
  var re = /^.{1,96}$/; // Alphanumeric only
  return structAddr && re.test(structAddr);
}

function validateEUI(eui) {
  var re = /^(?=.{16,16}$)[^\W_]+(?: [^\W_]+)*$/;
  return eui && re.test(eui);
}

function validateNameNoSpaceBeginning(name) {
  var re = /(?=.{1,24}$)^[a-zA-Z0-9_@.#&+-]+( [a-zA-Z0-9_@.#&+-]+)*$/;
  return name && re.test(name);
}

function validateHtmlColorName(color) {
  const validColor = ["white", "silver", "gray", "black", "red", "maroon", "yellow", "olive", "lime", "green", "aqua", "teal", "blue", "navy", "fuchsia", "purple"];
  return validColor.includes(color);
}

function validateCoordinate(coordinate) {
  var re = /^-?[0-9]{1,3}(?:\.[0-9]{1,10})?$/;
  return coordinate && re.test(coordinate);
}

function validateSensorName(name) {
  var re = /(?=.{1,16}$)^[a-zA-Z0-9_@.#&+-]+( [a-zA-Z0-9_@.#&+-]+)*$/;
  return name && re.test(name);
}

function validateStructSpans(spans) {
  var re = /^[1-9][0-9]*$/;
  return spans && re.test(spans);
}

/* User */
function validateUsername(username) {
  var re = /^[a-zA-Z0-9]{3,24}$/;
  return username && re.test(username);
}

function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && re.test(email.toString().toLowerCase());
}

function validateLoraAppID(loraAppID) {
  var re = /^[A-Z0-9]{8}$/;
  return loraAppID && re.test(loraAppID);
}

function validateGenericAppID(appID) {
  var re = /^[^\s@]+$/;
  return appID && re.test(appID);
}

function validateLoraToken(loraToken) {
  var re = /^\S{10,}$/; // Any 6+ char but space
  return loraToken && re.test(loraToken);
}

function validatePassword(psw) {
  // Minimum 7 characters. max 96 characters, at least one digit and one special character (# ? ! @ $ % ^ & * -)
  var re = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,96}$/;
  return psw && re.test(psw);
}

/* VALIDAZIONE PARAMETRI SENSORI */
function validateOffsetClassC(sensor, offsetClassC) {
  if (sensor.type === "accelerometer" && (sensor.rev === "3.0" || sensor.rev === "4.0")) {
    const value = offsetClassC;
    return !isNaN(value) && value >= 0 && value <= 59 && !(value === "");
  }
  return true;
}
function validateSubMulticast(sensor, subMulticast) {
  if (sensor.type === "accelerometer" && (sensor.rev === "3.0" || sensor.rev === "4.0")) {
    const value = subMulticast;
    return !isNaN(value) && value >= 0 && value <= 65535 && !(value === "");
  }
  return true;
}
function validateThreshold(sensor, alertThreshold) {
  if (sensor.type != "analog-node") {
    // Se è un inclinometro accetto anche 'undefined'
    if (sensor.type === "tiltmeter") {
      return alertThreshold === undefined || (!isNaN(alertThreshold) && alertThreshold !== "" && alertThreshold >= 0);
    } else {
      return !isNaN(alertThreshold) && alertThreshold != null && alertThreshold != undefined && alertThreshold != "";
    }
  } else {
    return true;
  }
}
function validateNumber(number, minMax) {
  return !isNaN(number) && number != null && number != undefined && number != "" && (minMax ? number >= minMax[0] && number <= minMax[1] : true);
}

function validateSpanLength(spanLength) {
  return !isNaN(spanLength) && spanLength != null && spanLength != undefined && spanLength != "" && spanLength > 0;
}
function validateActivationTh(sensor, activationThreshold) {
  const value = activationThreshold;
  const resolution = sensor.userConfig.resolution;
  let minValue;
  let maxValue;
  if (sensor.type === 'tiltmeter') {
    return true;
  }
  if (sensor.type === "vibrometer") {
    minValue = 0;
    maxValue = sensor.rev >= "2.0" ? sensor.userConfig.fullScale : 50;
  } else if(sensor.type === "deck") {
    minValue = resolution === 0.012 ? 0.06 : 0.12;
    maxValue = 1.2;
  }
  else if (sensor.type === "accelerometer" && (sensor.rev === "3.0" || sensor.rev === "4.0")) {
    switch (resolution) {
      case 0.03125:
        minValue = 0.062;
        maxValue = 511;
        break;
      case 0.0625:
        minValue = 0.124;
        maxValue = 999;
        break;
      case 0.125:
        minValue = 0.25;
        maxValue = 1999;
        break;
      default:
        return false;
    }
  } else {
    minValue = 0;
    maxValue = 2048;
  }
  return !isNaN(value) && value >= minValue && value <= maxValue && !(value === "");
}

function validateEntireSensor(sensor) {
  const userConfig = sensor.userConfig;
  const validBase = validateEUI(sensor.eui);
  let validUserConfig = true; //sensor.type != "tiltmeter" ? validateThreshold(sensor, userConfig.alertThreshold) : validateThreshold(sensor, userConfig.alertThresholdTheta) && validateThreshold(sensor, userConfig.alertThresholdPhi);
  if (userConfig.name != null && userConfig.name != undefined) {
    validUserConfig = validUserConfig && validateSensorName(userConfig.name);
  }
  if (userConfig.threshold != null && userConfig.threshold != undefined) {
    // Se è un vibromter
    if (sensor.type === "vibrometer") {
      // Se ho una soglia custom la devo validare, altrimenti do per scontato che sia ok
      if (userConfig.threshold.normativeName === "custom") {
        validUserConfig = validUserConfig && validateNumber(userConfig.threshold.customThreshold, [0, 8192]);
        validUserConfig = validUserConfig && userConfig.threshold.customOperation !== null && userConfig.threshold.customOperation !== undefined;
        validUserConfig = validUserConfig && userConfig.threshold.customAxes !== null && userConfig.threshold.customAxes !== undefined && userConfig.threshold.customAxes.length > 0;
      }
    } else {
      validUserConfig = validUserConfig && validateThreshold(sensor, userConfig.threshold);
    }
  }
  if (userConfig.alertThresholdTheta != null && userConfig.alertThresholdTheta != undefined) {
    validUserConfig = validUserConfig && validateThreshold(sensor, userConfig.alertThresholdTheta);
  }
  if (userConfig.alertThresholdPhi != null && userConfig.alertThresholdPhi != undefined) {
    validUserConfig = validUserConfig && validateThreshold(sensor, userConfig.alertThresholdPhi);
  }
  if (userConfig.workingThreshold != null && userConfig.workingThreshold != undefined) {
    validUserConfig = validUserConfig && validateActivationTh(sensor, userConfig.workingThreshold);
  }
  if (userConfig.activationThreshold != null && userConfig.activationThreshold != undefined) {
    validUserConfig = validUserConfig && validateActivationTh(sensor, userConfig.activationThreshold);
  }
  if (userConfig.offsetClassC != null && userConfig.offsetClassC != undefined) {
    validUserConfig = validUserConfig && validateOffsetClassC(sensor, userConfig.offsetClassC);
  }
  if (userConfig.subMulticast != null && userConfig.subMulticast != undefined) {
    validUserConfig = validUserConfig && validateSubMulticast(sensor, userConfig.subMulticast);
  }
  return validBase && validUserConfig;
}

function validateEntireStructure(structure) {
  const validName = validateStructName(structure.name);
  const validCoordinates = validateCoordinate(structure.coordinates.lng) && validateCoordinate(structure.coordinates.lat);
  const validSpans = !structure.userConfig.spansDetails || !structure.userConfig.spansDetails.some((span) => !validateEntireSpan(span));
  return validName && validCoordinates && validSpans;
}

function validateEntireSpan(span) {
  const validName = validateSpanName(span.name);
  const validSpanLength = validateSpanLength(span.length);
  return validName && validSpanLength;
}

function validateEntireMail(mail) {
  return validateEmail(mail.mail) && validateName(mail.name);
}


module.exports = {
  validateFullName,
  validateName,
  validateStructName,
  validateSpanName,
  validateSpanLength,
  validateStructAddress,
  validateEUI,
  validateNameNoSpaceBeginning,
  validateHtmlColorName,
  validateCoordinate,
  validateSensorName,
  validateStructSpans,
  validateUsername,
  validateEmail,
  validateLoraAppID,
  validateGenericAppID,
  validateLoraToken,
  validatePassword,
  validateOffsetClassC,
  validateSubMulticast,
  validateThreshold,
  validateNumber,
  validateActivationTh,
  validateEntireSensor,
  validateEntireStructure,
  validateEntireSpan,
  validateEntireMail,
};
