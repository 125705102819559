<template>
  <div class="loading">
    <div class="inner-loading">
      <img :src="imageURL" />
      <div class="load-slide">
        <div class="line"></div>
        <div class="subline inc"></div>
        <div class="subline dec"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "loading-logo",
  computed: {
    ...mapGetters({ customLogo: "user/getUserLogoURL" }),
    imageURL() {
      return this.customLogo || require("../../../public/assets/loading-logo.svg");
    },
  },
};
</script>
<style scoped>
.loading {
  width: 100%;
  top: 0;
  left: 0;
  background-color: black;
  height: 100%;
  position: absolute;
  z-index: 12;
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  opacity: 0.7;
  border-radius: inherit;
}

.inner-loading {
  height: 600px;
  width: 600px;
  /* position: absolute; */
  position: relative;
  margin: auto;
  justify-content: center;
  display: flex;
}

img {
  position: absolute;
  width: 200px;
  top: 30%;
}
.load-slide {
  position: relative;
  width: 300px;
  height: 7px;
  overflow-x: hidden;
  display: flex;
  margin-top: 380px;
}

.line {
  position: absolute;
  opacity: 0.4;
  background: white;
  width: 150%;
  height: 7px;
}

.subline {
  position: absolute;
  background: white;
  height: 7px;
}
.inc {
  animation: increase 2s infinite;
}
.dec {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}
</style>
